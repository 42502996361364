<template>
    <tr>
        <td class="okrFirstColumn w-[30%]"></td>
        <td class="okrSecondColumn w-[30%]"></td>
        <td class="font-bold text-black w-[210%]">Name</td>              
        <td class="font-bold text-black w-[100%] text-center">Team</td>
        <td class="font-bold text-black w-[100%] text-center">Owner</td>
        <td class="font-bold text-black w-[150%] text-center">Progress</td>
        <!-- <td class="font-bold text-black w-[90%] text-center">Status</td> -->
        <td class="font-bold text-black w-[100%] text-center">Zeitraum</td>
        <td class="font-bold text-black w-[130%] text-center">Aktionen</td>
    </tr>
</template>

<script>

export default {
    name: 'Component_OKR_Header',
}
</script>
