<template>
    <tr>
        <td class="font-bold text-black w-[100%]">Nutzer</td>    
        <td class="font-bold text-black w-[150%] ">Objektname</td>
          
        <td class="font-bold text-black w-[40%] text-center">Aktion</td>
        <td class="font-bold text-black w-[40%] text-center">Objekttyp</td>
        <td class="font-bold text-black w-[80%] text-center">Zeit</td>
    </tr>
</template>

<script>

export default {
    name: 'Component_Changelog_Header',
}
</script>
