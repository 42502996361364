<template>
    <div class="privacy-statement">
        <h1>Datenschutzerklärung</h1>
        <p>
            Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat einen besonders hohen Stellenwert für die Geschäftsleitung der VANTA Tools GmbH (in gründung). Eine Nutzung der Internetseiten der VANTA Tools GmbH ist grundsätzlich ohne jede Angabe personenbezogener Daten möglich. Sofern eine betroffene Person besondere Services unseres Unternehmens über unsere Internetseite in Anspruch nehmen möchte, könnte jedoch eine Verarbeitung personenbezogener Daten erforderlich werden.
        </p>
        <h2>1. Begriffsbestimmungen</h2>
        <p>
            Die Datenschutzerklärung der VANTA Tools GmbH beruht auf den Begrifflichkeiten, die durch den Europäischen Richtlinien- und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DS-GVO) verwendet wurden.
        </p>
        <h2>2. Name und Anschrift des für die Verarbeitung Verantwortlichen</h2>
        <p>
            Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten der Europäischen Union geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem Charakter ist die:
        </p>
        <p>
            VANTA Tools GmbH<br>
            Musterstraße 1<br>
            12345 Musterstadt<br>
            Deutschland<br>
            Tel.: +49 XXXX<br>
            E-Mail: info@VANTAToolsGmbH.de
        </p>
        <h2>3. Erfassung von allgemeinen Daten und Informationen</h2>
        <p>
            Die Internetseite der Djackets Luka erfasst mit jedem Aufruf der Internetseite durch eine betroffene Person oder ein automatisiertes System eine Reihe von allgemeinen Daten und Informationen.
        </p>
        <h2>Verwendung von Cookies</h2>
        <p>Unsere Website verwendet nur technisch notwendige Cookies, die für den Betrieb der Website und die Bereitstellung der von Ihnen ausdrücklich gewünschten Dienste erforderlich sind. Diese Cookies speichern keine personenbezogenen Daten und erfordern keine Einwilligung.</p>
        <!-- Weitere Abschnitte der Datenschutzerklärung können hier hinzugefügt werden -->
    </div>
</template>

<script>
export default {
    name: 'TermsAndConditions'
}
</script>

<style scoped>
.privacy-statement {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.privacy-statement h1, .privacy-statement h2 {
    color: #333;
}

.privacy-statement p {
    color: #666;
    line-height: 1.6;
}
</style>