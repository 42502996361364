<template>
    <!-- <button class="tooltip-btn" @click="showTooltip = !showTooltip">?</button>
    <transition name="modal">
    <div v-if="showTooltip" class="modal-bg-tooltip">
        <div class="modal-tooltip">
            <div >
                {{infoText}}
            </div>
        </div>
    </div>
    </transition> -->
<div class="group" ref="parent">
    <InformationCircleIcon class="h-10 w-10 text-black" />
    <button 
        :class="{'tooltip-btn-clicked': showTooltip}" 
        class="tooltip-btn" 
        @click="toggle">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
        </svg>

        
    </button>
    <transition name="modal">
      <div v-if="showTooltip" class="modal-tooltip">
                {{infoText}}
        </div>
    </transition>

    </div>
</template>

<script>


export default {
    name: 'Component_Modal_InfoButton',
    props: {
        infoText: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            showTooltip: false,
            dynamicClasses: '',
        }
    },
    methods: {
        toggle() {
            // this.setTooltipPosition();
            this.showTooltip = !this.showTooltip;
        },
        setTooltipPosition() {
            this.$nextTick(() => {
                const parent = this.$refs.parent.getBoundingClientRect();
                this.dynamicClasses = `top-[${parent.top + window.scrollY}px] left-[${parent.left + 100}px] w-[150px]`;
            });
        }
    }
}
</script>