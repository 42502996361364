<template>
    <div class="flex flex-col items-center">
        <div v-if="permissions.includes(`olp_change_mission_${mission.id}`) && permissions.includes('product.add_mission')">
            <button @click="isModalOpen = true" class="button is-ghost">
                <strong>
                    {{ mission.name }}
                </strong>
            </button>
            <Teleport to="#modal">
                <Transition name="modal">
                    <div v-if="isModalOpen" class="modal-bg" @click.self="isModalOpen = false">
                        <div class="modal">
                            <button @click="isModalOpen = false" class="close-btn">x</button>
                            <Modal_ChangeMission
                            v-if="permissions.includes(`olp_change_mission_${mission.id}`)"
                            v-bind:mission="mission"
                            v-on:missionChanged="handleMissionChanged"
                            />
                        </div>
                    </div>
                </Transition>
            </Teleport>
        </div>
        <strong v-else class="overview-header">
            {{ mission.name }}
        </strong>
    </div>

    <div class="overview-background-wrapper">
        <div class="overview-background bg-[#80c1ff]" v-for="moal in this.filteredMoals" v-bind:key="moal.id">
            <div class="actually-centered">
                <button class="button is-dark round-button-small ">x{{ moal.weight_multiplier }}</button>
                <Component_Overview_Moal
                        v-bind:moal="moal"
                        v-bind:objectives="objectives"
                        v-bind:keyresults="keyresults"
                        v-bind:permissions="permissions"
                        v-on:moalChanged="handleMissionChanged"
                >
                </Component_Overview_Moal>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
import Component_Overview_Moal from './Component_Overview_Moal.vue'
import Modal_ChangeMission from './Modal_ChangeMission.vue'


export default {
    name: 'Component_Overview_Mission',
    emits: ['missionChanged'],
    components:
    {
        Component_Overview_Moal,
        Modal_ChangeMission,
    },
    props: {
        mission: Object,
        moals: Array,
        objectives: Array,
        keyresults: Array,
        permissions: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            filteredMoals: [],
            isModalOpen: false,
        }
    },
    mounted() {
        this.filterMoals();
    },
    methods: { 
        async filterMoals() {
            this.filteredMoals = this.moals.filter(moal => moal.mission === this.mission.id);
        },
        handleMissionChanged() {
            this.isModalOpen = false;
            this.$emit('missionChanged');
            fetchMoals(props.mission.id)
        },
    },
}
</script>