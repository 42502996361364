<template>
    <div class="infopage">
        <h1>Datenschutzerklärung</h1>
        <h1 id="Präambel">Präambel</h1>

            <p>
                Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als "Daten" bezeichnet) wir zu welchen Zwecken und in welchem Umfang im Rahmen der Bereitstellung unserer Applikation verarbeiten.
            </p>
            <p>
                Die verwendeten Begriffe sind nicht geschlechtsspezifisch.
            </p>
            <p>
                Stand: 30. Oktober 2024
            </p>
        <h1>Inhaltsübersicht</h1>
            <ul>
                <li><a class="link-infotext" href="#Präambel">Präambel</a></li>
                <li><a class="link-infotext" href="#Verantwortlicher">Verantwortlicher</a></li>
                <li><a class="link-infotext" href="#verantwortliche">Name und Anschrift des Verantwortlichen</a></li>
                <li><a class="link-infotext" href="#Verarbeitungen">Übersicht der Verarbeitungen</a></li>
                <li><a class="link-infotext" href="#Rechtsgrundlagen">Maßgebliche Rechtsgrundlagen</a></li>
                <li><a class="link-infotext" href="#Sicherheitsmaßnahmen">Sicherheitsmaßnahmen</a></li>
                <li><a class="link-infotext" href="#Daten">Übermittlung von personenbezogenen Daten</a></li>
                <li><a class="link-infotext" href="#Datentransfers">Datentransfers</a></li>
                <li><a class="link-infotext" href="#Datenspeicherung">Informationen zur Datenspeicherung und Löschung</a></li>
                <li><a class="link-infotext" href="#Rechte">Rechte der betroffenen Personen</a></li>
                <li><a class="link-infotext" href="#Leistungen">Leistungen</a></li>
                <li><a class="link-infotext" href="#Geschäftsprozesse">Geschäftsprozesse und -verfahren</a></li>
                <li><a class="link-infotext" href="#Onlineangebot">Bereitstellung des Onlineangebots und Webhosting</a></li>
                <li><a class="link-infotext" href="#Cookies">Einsatz von Cookies</a></li>
                <li><a class="link-infotext" href="#Webanalyse">Webanalyse, Kontakt- und Anfrageverwaltung</a></li>
                <li><a class="link-infotext" href="#Monitoring">Monitoring und Optimierung</a></li>
                <li><a class="link-infotext" href="#Plug-ins">Plug-ins und eingebettete Funktionen sowie Inhalte</a></li>
                <li><a class="link-infotext" href="#Änderung">Änderung und Aktualisierung</a></li>
                <li><a class="link-infotext" href="#Begriffsdefinitionen">Begriffsdefinitionen</a></li>
            </ul>
        <h1 id="Verantwortlicher">Verantwortlicher</h1>
            <p>
                Moritz Reiss<br>
                Hildastraße 9D<br>
                76534 Baden-Baden
            </p>
            <p>
                Sven Willems<br>
                Danzigerstraße 4<br>
                76434 Rastatt
            </p>
            <p>
                Luka Johnsen<br>
                Davenstedter Str. 269<br>
                30455 Hannover
            </p>
            <p>
                E-Mail-Adresse: 
                <a href="mailto:post@sustevia.de" class="link-infotext">post@sustevia.de</a>
            </p>
            <p>
                <RouterLink class="link-infotext" to="/imprint">Impressum</RouterLink>
            </p>

        <h1 id="Verarbeitungen">Übersicht der Verarbeitungen</h1>
            <p>
                Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen Personen.
            </p>

            <h2>Arten der verarbeiteten Daten</h2>
                <ul>
                    <li>Bestandsdaten.</li>
                    <li>Zahlungsdaten.</li>
                    <li>Kontaktdaten.</li>
                    <li>Inhaltsdaten.</li>
                    <li>Nutzungsdaten.</li>
                    <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
                    <li>Protokolldaten.</li>
                </ul>
            <h1>Kategorien betroffener Personen</h1>
                <ul>
                    <li>Leistungsempfänger und Auftraggeber.</li>
                    <li>Interessenten.</li>
                    <li>Kommunikationspartner.</li>
                    <li>Nutzer.</li>
                    <li>Geschäfts- und Vertragspartner.</li>
                    <li>Kunden.</li>
                </ul>
            <h2>Zwecke der Verarbeitung</h2>
                <ul>
                    <li>Erbringung vertraglicher Leistungen und Erfüllung vertraglicher Pflichten.</li>
                    <li>Kommunikation.</li>
                    <li>Sicherheitsmaßnahmen.</li>
                    <li>Reichweitenmessung.</li>
                    <li>Büro- und Organisationsverfahren.</li>
                    <li>Organisations- und Verwaltungsverfahren.</li>
                    <li>Feedback.</li>
                    <li>Profile mit nutzerbezogenen Informationen.</li>
                    <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
                    <li>Informationstechnische Infrastruktur.</li>
                    <li>Geschäftsprozesse und betriebswirtschaftliche Verfahren.</li>
                </ul>

        <h1 id="Rechtsgrundlagen">Maßgebliche Rechtsgrundlagen</h1>
            <p>
                <strong>Maßgebliche Rechtsgrundlagen nach der DSGVO: </strong>Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.
            </p>
            <ul>
                <li>
                    <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO</strong>
                    - Die betroffene Person hat ihre Einwilligung in die Verarbeitung der sie betreffenden personenbezogenen Daten für einen spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
                </li>
                <li>
                    <strong>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO) </strong>
                    - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen Person erfolgen.
                </li>
                <li>
                    <strong>Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO) </strong>
                    - Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der der Verantwortliche unterliegt.
                </li>
                <li>
                    <strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO) </strong>
                    - die Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten notwendig, vorausgesetzt, dass die Interessen, Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten verlangen, nicht überwiegen.
                </li>
            </ul>
            <p>
                <strong>Nationale Datenschutzregelungen in Deutschland: </strong>
                Zusätzlich zu den Datenschutzregelungen der DSGVO gelten nationale Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall einschließlich Profiling. Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung gelangen.
            </p>
            <p>
                <strong>Hinweis auf Geltung DSGVO und Schweizer DSG: </strong>
                Diese Datenschutzhinweise dienen sowohl der Informationserteilung nach dem Schweizer DSG als auch nach der Datenschutzgrundverordnung (DSGVO). Aus diesem Grund bitten wir Sie zu beachten, dass aufgrund der breiteren räumlichen Anwendung und Verständlichkeit die Begriffe der DSGVO verwendet werden. Insbesondere statt der im Schweizer DSG verwendeten Begriffe „Bearbeitung" von „Personendaten", "überwiegendes Interesse" und "besonders schützenswerte Personendaten" werden die in der DSGVO verwendeten Begriffe „Verarbeitung" von „personenbezogenen Daten" sowie "berechtigtes Interesse" und "besondere Kategorien von Daten" verwendet. Die gesetzliche Bedeutung der Begriffe wird jedoch im Rahmen der Geltung des Schweizer DSG weiterhin nach dem Schweizer DSG bestimmt.
            </p>

        <h1 id="Sicherheitsmaßnahmen">Sicherheitsmaßnahmen</h1>
            <p>
                Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.
            </p>
            <p>
                Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
            </p>
            <p>
                Sicherung von Online-Verbindungen durch TLS-/SSL-Verschlüsselungstechnologie (HTTPS): Um die Daten der Nutzer, die über unsere Online-Dienste übertragen werden, vor unerlaubten Zugriffen zu schützen, setzen wir auf die TLS-/SSL-Verschlüsselungstechnologie. Secure Sockets Layer (SSL) und Transport Layer Security (TLS) sind die Eckpfeiler der sicheren Datenübertragung im Internet. Diese Technologien verschlüsseln die Informationen, die zwischen der Website oder App und dem Browser des Nutzers (oder zwischen zwei Servern) übertragen werden, wodurch die Daten vor unbefugtem Zugriff geschützt sind. TLS, als die weiterentwickelte und sicherere Version von SSL, gewährleistet, dass alle Datenübertragungen den höchsten Sicherheitsstandards entsprechen. Wenn eine Website durch ein SSL-/TLS-Zertifikat gesichert ist, wird dies durch die Anzeige von HTTPS in der URL signalisiert. Dies dient als ein Indikator für die Nutzer, dass ihre Daten sicher und verschlüsselt übertragen werden.
            </p>

        <h1 id="Daten">Übermittlung von personenbezogenen Daten</h1>
            <p>
                Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass diese an andere Stellen, Unternehmen, rechtlich selbstständige Organisationseinheiten oder Personen übermittelt beziehungsweise ihnen gegenüber offengelegt werden. Zu den Empfängern dieser Daten können z. B. mit IT-Aufgaben beauftragte Dienstleister gehören oder Anbieter von Diensten und Inhalten, die in eine Website eingebunden sind. In solchen Fällen beachten wir die gesetzlichen Vorgaben und schließen insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.
            </p>
        <h1 id="Datentransfers">Internationale Datentransfers</h1>
            <p>
                Datenverarbeitung in Drittländern: Sofern wir Daten in einem Drittland (d. h., außerhalb der Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw. Übermittlung von Daten an andere Personen, Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben. Sofern das Datenschutzniveau in dem Drittland mittels eines Angemessenheitsbeschlusses anerkannt wurde (Art. 45 DSGVO), dient dieser als Grundlage des Datentransfers. Im Übrigen erfolgen Datentransfers nur dann, wenn das Datenschutzniveau anderweitig gesichert ist, insbesondere durch Standardvertragsklauseln (Art. 46 Abs. 2 lit. c) DSGVO), ausdrückliche Einwilligung oder im Fall vertraglicher oder gesetzlich erforderlicher Übermittlung (Art. 49 Abs. 1 DSGVO). Im Übrigen teilen wir Ihnen die Grundlagen der Drittlandübermittlung bei den einzelnen Anbietern aus dem Drittland mit, wobei die Angemessenheitsbeschlüsse als Grundlagen vorrangig gelten. Informationen zu Drittlandtransfers und vorliegenden Angemessenheitsbeschlüssen können dem Informationsangebot der EU-Kommission entnommen werden: 
                <a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de" class="link-infotext">https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de</a>.
                Im Rahmen des sogenannten „Data Privacy Framework" (DPF) hat die EU-Kommission das Datenschutzniveau ebenfalls für bestimmte Unternehmen aus den USA im Rahmen der Angemessenheitsbeschlusses vom 10.07.2023 als sicher anerkannt. Die Liste der zertifizierten Unternehmen als auch weitere Informationen zu dem DPF können Sie der Website des Handelsministeriums der USA unter
                <a href="https://www.dataprivacyframework.gov/" class="link-infotext">https://www.dataprivacyframework.gov/ </a>
                (in Englisch) entnehmen. Wir informieren Sie im Rahmen der Datenschutzhinweise, welche von uns eingesetzten Diensteanbieter unter dem Data Privacy Framework zertifiziert sind.
            </p>
        <h1 id="Datenspeicherung">Allgemeine Informationen zur Datenspeicherung und Löschung</h1>
            <P>Wir löschen personenbezogene Daten, die wir verarbeiten, gemäß den gesetzlichen Bestimmungen, sobald die zugrundeliegenden Einwilligungen widerrufen werden oder keine weiteren rechtlichen Grundlagen für die Verarbeitung bestehen. Dies betrifft Fälle, in denen der ursprüngliche Verarbeitungszweck entfällt oder die Daten nicht mehr benötigt werden. Ausnahmen von dieser Regelung bestehen, wenn gesetzliche Pflichten oder besondere Interessen eine längere Aufbewahrung oder Archivierung der Daten erfordern.</P>
            <P>Insbesondere müssen Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung notwendig ist zur Rechtsverfolgung oder zum Schutz der Rechte anderer natürlicher oder juristischer Personen, entsprechend archiviert werden.</P>
            <P>Unsere Datenschutzhinweise enthalten zusätzliche Informationen zur Aufbewahrung und Löschung von Daten, die speziell für bestimmte Verarbeitungsprozesse gelten.</P>
            <P>Bei mehreren Angaben zur Aufbewahrungsdauer oder Löschungsfristen eines Datums, ist stets die längste Frist maßgeblich.</P>
            <P>Beginnt eine Frist nicht ausdrücklich zu einem bestimmten Datum und beträgt sie mindestens ein Jahr, so startet sie automatisch am Ende des Kalenderjahres, in dem das fristauslösende Ereignis eingetreten ist. Im Fall laufender Vertragsverhältnisse, in deren Rahmen Daten gespeichert werden, ist das fristauslösende Ereignis der Zeitpunkt des Wirksamwerdens der Kündigung oder sonstige Beendigung des Rechtsverhältnisses.</P>
            <P>Daten, die nicht mehr für den ursprünglich vorgesehenen Zweck, sondern aufgrund gesetzlicher Vorgaben oder anderer Gründe aufbewahrt werden, verarbeiten wir ausschließlich zu den Gründen, die ihre Aufbewahrung rechtfertigen.</P>
            <h2>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</h2>
                <ul>
                    <li>
                        <strong>Aufbewahrung und Löschung von Daten: </strong>
                        Die folgenden allgemeinen Fristen gelten für die Aufbewahrung und Archivierung nach deutschem Recht: 
                        <ul class="sublist">
                            <li>
                                10 Jahre - Aufbewahrungsfrist für Bücher und Aufzeichnungen, Jahresabschlüsse, Inventare, Lageberichte, Eröffnungsbilanz sowie die zu ihrem Verständnis erforderlichen Arbeitsanweisungen und sonstigen Organisationsunterlagen, Buchungsbelege und Rechnungen (§ 147 Abs. 3 i. V. m. Abs. 1 Nr. 1, 4 und 4a AO, § 14b Abs. 1 UStG, § 257 Abs. 1 Nr. 1 u. 4, Abs. 4 HGB).
                            </li>
                            <li>
                                o	6 Jahre - Übrige Geschäftsunterlagen: empfangene Handels- oder Geschäftsbriefe, Wiedergaben der abgesandten Handels- oder Geschäftsbriefe, sonstige Unterlagen, soweit sie für die Besteuerung von Bedeutung sind, z. B. Stundenlohnzettel, Betriebsabrechnungsbögen, Kalkulationsunterlagen, Preisauszeichnungen, aber auch Lohnabrechnungsunterlagen, soweit sie nicht bereits Buchungsbelege sind und Kassenstreifen (§ 147 Abs. 3 i. V. m. Abs. 1 Nr. 2, 3, 5 AO, § 257 Abs. 1 Nr. 2 u. 3, Abs. 4 HGB).
                            </li>
                            <li>
                                o	3 Jahre - Daten, die erforderlich sind, um potenzielle Gewährleistungs- und Schadensersatzansprüche oder ähnliche vertragliche Ansprüche und Rechte zu berücksichtigen sowie damit verbundene Anfragen zu bearbeiten, basierend auf früheren Geschäftserfahrungen und üblichen Branchenpraktiken, werden für die Dauer der regulären gesetzlichen Verjährungsfrist von drei Jahren gespeichert (§§ 195, 199 BGB).
                            </li>                   
                        </ul>
                    </li>
                </ul>

        <h1 id="Rechte">Rechte der betroffenen Personen</h1>
            <p>
                Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:
            </p>
            <ul>
                <li>
                    <strong>Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</strong>
                </li>
                <li>
                    <strong>Widerrufsrecht bei Einwilligungen: </strong>
                    Sie haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen.
                </li>
                <li>
                    <strong>Auskunftsrecht: </strong>
                    Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.
                </li>
                <li>
                    <strong>Recht auf Berichtigung: </strong>
                    Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
                </li>
                <li>
                    <strong>Recht auf Löschung und Einschränkung der Verarbeitung: </strong>
                    Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten zu verlangen.
                </li>
                <li>
                    <strong>Recht auf Datenübertragbarkeit: </strong>
                    Sie haben das Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen anderen Verantwortlichen zu fordern.
                </li>
                <li>
                    <strong>Beschwerde bei Aufsichtsbehörde: </strong>
                    Sie haben unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die Vorgaben der DSGVO verstößt.
                </li>
            </ul>

        <h1 id="Leistungen">Geschäftliche Leistungen</h1>
            <p>
                Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z. B. Kunden und Interessenten (zusammenfassend als „Vertragspartner" bezeichnet), im Rahmen von vertraglichen und vergleichbaren Rechtsverhältnissen sowie damit verbundenen Maßnahmen und im Hinblick auf die Kommunikation mit den Vertragspartnern (oder vorvertraglich), etwa zur Beantwortung von Anfragen.
            </p>
            <p>
                Wir verwenden diese Daten, um unsere vertraglichen Verpflichtungen zu erfüllen. Dazu gehören insbesondere die Pflichten zur Erbringung der vereinbarten Leistungen, etwaige Aktualisierungspflichten und Abhilfe bei Gewährleistungs- und sonstigen Leistungsstörungen. Darüber hinaus verwenden wir die Daten zur Wahrung unserer Rechte und zum Zwecke der mit diesen Pflichten verbundenen Verwaltungsaufgaben sowie der Unternehmensorganisation. Zudem verarbeiten wir die Daten auf Grundlage unserer berechtigten Interessen sowohl an einer ordnungsgemäßen und betriebswirtschaftlichen Geschäftsführung als auch an Sicherheitsmaßnahmen zum Schutz unserer Vertragspartner und unseres Geschäftsbetriebs vor Missbrauch, Gefährdung ihrer Daten, Geheimnisse, Informationen und Rechte (z. B. zur Beteiligung von Telekommunikations-, Transport- und sonstigen Hilfsdiensten sowie Subunternehmern, Banken, Steuer- und Rechtsberatern, Zahlungsdienstleistern oder Finanzbehörden). Im Rahmen des geltenden Rechts geben wir die Daten von Vertragspartnern nur insoweit an Dritte weiter, als dies für die vorgenannten Zwecke oder zur Erfüllung gesetzlicher Pflichten erforderlich ist. Über weitere Formen der Verarbeitung, etwa zu Marketingzwecken, werden die Vertragspartner im Rahmen dieser Datenschutzerklärung informiert.
            </p>
            <p>
                Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den Vertragspartnern vor oder im Rahmen der Datenerhebung, z. B. in Onlineformularen, durch besondere Kennzeichnung (z. B. Farben) bzw. Symbole (z. B. Sternchen o. Ä.), oder persönlich mit.
            </p>
            <p>
                Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten, d. h. grundsätzlich nach vier Jahren, es sei denn, dass die Daten in einem Kundenkonto gespeichert werden, z. B., solange sie aus gesetzlichen Gründen der Archivierung aufbewahrt werden müssen (etwa für Steuerzwecke im Regelfall zehn Jahre). Daten, die uns im Rahmen eines Auftrags durch den Vertragspartner offengelegt wurden, löschen wir entsprechend den Vorgaben und grundsätzlich nach Ende des Auftrags.
            </p>
            <ul>
                <li>
                    <strong>Verarbeitete Datenarten: </strong>
                    Bestandsdaten (z. B. der vollständige Name, Wohnadresse, Kontaktinformationen, Kundennummer, etc.); Zahlungsdaten (z. B. Bankverbindungen, Rechnungen, Zahlungshistorie); Kontaktdaten (z. B. Post- und E-Mail-Adressen oder Telefonnummern). Vertragsdaten (z. B. Vertragsgegenstand, Laufzeit, Kundenkategorie).
                </li>
                <li>
                    <strong>Betroffene Personen: </strong>
                    Leistungsempfänger und Auftraggeber; Interessenten. Geschäfts- und Vertragspartner.
                </li>
                <li>
                    <strong>Zwecke der Verarbeitung: </strong>
                    Erbringung vertraglicher Leistungen und Erfüllung vertraglicher Pflichten; Kommunikation; Büro- und Organisationsverfahren; Organisations- und Verwaltungsverfahren. Geschäftsprozesse und betriebswirtschaftliche Verfahren.
                </li>
                <li>
                    <strong>Aufbewahrung und Löschung: </strong>
                    Löschung entsprechend Angaben im Abschnitt "Allgemeine Informationen zur Datenspeicherung und Löschung".
                </li>
                <li>
                    <strong>Rechtsgrundlagen: </strong>
                    Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO); Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                </li>
            </ul>
            <h2>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</h2>
                <ul>
                    <li>  
                        <strong>Angebot von Software- und Plattformleistungen: </strong>
                        Wir verarbeiten die Daten unserer Nutzer, angemeldeter und etwaiger Testnutzer (nachfolgend einheitlich als "Nutzer" bezeichnet), um ihnen gegenüber unsere vertraglichen Leistungen erbringen zu können sowie auf Grundlage berechtigter Interessen, um die Sicherheit unseres Angebotes gewährleisten und es weiterentwickeln zu können. Die erforderlichen Angaben sind als solche im Rahmen des Auftrags-, Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und umfassen die zur Leistungserbringung und Abrechnung benötigten Angaben sowie Kontaktinformationen, um etwaige Rücksprachen halten zu können; 
                        <strong>Rechtsgrundlagen: </strong>
                        Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                    </li>
                </ul>
        <h1 id="Geschäftsprozesse">Geschäftsprozesse und -verfahren</h1>
            <p>
                Personenbezogene Daten von Leistungsempfängern und Auftraggebern – darunter Kunden, Klienten oder in speziellen Fällen Mandanten, Patienten oder Geschäftspartner sowie weitere Dritte – werden im Rahmen vertraglicher sowie vergleichbarer Rechtsverhältnisse und vorvertraglicher Maßnahmen wie der Anbahnung von Geschäftsbeziehungen verarbeitet. Diese Datenverarbeitung unterstützt und erleichtert betriebswirtschaftliche Abläufe in Bereichen wie Kundenmanagement, Vertrieb, Zahlungsverkehr, Buchhaltung und Projektmanagement.
            </p>
            <p>
                Die erfassten Daten dienen dazu, vertragliche Verpflichtungen zu erfüllen und betriebliche Prozesse effizient zu gestalten. Hierzu gehört die Abwicklung von Geschäftstransaktionen, das Management von Kundenbeziehungen, die Optimierung von Vertriebsstrategien sowie die Gewährleistung interner Rechnungs- und Finanzprozesse. Zusätzlich unterstützen die Daten die Wahrung der Rechte des Verantwortlichen und fördern Verwaltungsaufgaben sowie die Organisation des Unternehmens.
            </p>
            <p>
                Personenbezogene Daten können an Dritte weitergegeben werden, sofern dies zur Erfüllung der genannten Zwecke oder gesetzlicher Verpflichtungen notwendig - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. 
            </p>
            <ul>
                <li>
                    <strong>Verarbeitete Datenarten: </strong>
                    Bestandsdaten (z. B. der vollständige Name, Wohnadresse, Kontaktinformationen, Kundennummer, - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. - premiumtext premiumtext premiumtext ); Zahlungsdaten (z. B. Bankverbindungen, Rechnungen, Zahlungshistorie - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. - premiumtext premiumtext ); Kontaktdaten (z. B. Post- und E-Mail-Adressen oder - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. - premiumtext premiumtext premiumtext ); Inhaltsdaten (z. B. textliche oder bildliche Nachrichten und Beiträge sowie die sie betreffenden Informationen, wie z. B. Angaben zur Autorenschaft - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. - premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext ); Vertragsdaten (z. B. Vertragsgegenstand, Laufzeit, Kundenkategorie - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. - premiumtext premiumtext ); Nutzungsdaten (z. B. Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und -frequenz, verwendete Gerätetypen und Betriebssysteme, Interaktionen mit - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. - premiumtext premiumtext premiumtext premiumtext premiumtext ). Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. 
                </li>
                <li>
                    <strong>Betroffene Personen: </strong>
                    Leistungsempfänger und Auftraggeber; Interessenten; Kommunikationspartner; Geschäfts- und Vertragspartner. Kunden.
                </li>
                <li>
                    <strong>Zwecke der Verarbeitung: </strong>
                    Erbringung vertraglicher Leistungen und Erfüllung vertraglicher Pflichten; Büro- und Organisationsverfahren; Geschäftsprozesse und betriebswirtschaftliche Verfahren; Sicherheitsmaßnahmen. Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
                </li>
                <li>
                    <strong>Aufbewahrung und Löschung: </strong>
                    Löschung entsprechend Angaben im Abschnitt "Allgemeine Informationen zur Datenspeicherung und Löschung".
                </li>
                <li>
                    <strong>Rechtsgrundlagen: </strong>
                    Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                </li>
            </ul>
            <h2>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</h2>
                <ul>
                    <li>
                        <strong>Kundenkonto: </strong>
                        Kunden können innerhalb unseres Onlineangebotes ein Konto anlegen (z. B. Kunden- bzw. Nutzerkonto, kurz "Kundenkonto"). Falls die Registrierung eines Kundenkontos erforderlich ist, werden Kunden hierauf ebenso hingewiesen wie auf die für die Registrierung erforderlichen Angaben. Die Kundenkonten sind nicht öffentlich und können von Suchmaschinen nicht indexiert werden. Im Rahmen der Registrierung sowie anschließender Anmeldungen und Nutzungen des Kundenkontos speichern wir die IP-Adressen der Kunden nebst den Zugriffszeitpunkten, um die Registrierung nachweisen und etwaigem Missbrauch des Kundenkontos vorbeugen zu können. Wurde das Kundenkonto gekündigt, werden die Daten des Kundenkontos nach dem Kündigungszeitpunkt gelöscht, sofern sie nicht für andere Zwecke als die Bereitstellung im Kundenkonto aufbewahrt werden oder aus rechtlichen Gründen aufbewahrt werden müssen (z. B. interne Speicherung von Kundendaten, Bestellvorgängen oder Rechnungen). Es liegt in der Verantwortung der Kunden, ihre Daten bei Kündigung des Kundenkontos zu sichern;
                        <strong>Rechtsgrundlagen: </strong>
                        Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                    </li>
                </ul>
        <h1>Bereitstellung des Onlineangebots und Webhostings</h1>
            <p>
                Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen unserer Online-Dienste an den Browser oder das Endgerät der Nutzer zu übermitteln.
            </p> 
            <p>
                <strong>Verarbeitete Datenarten: </strong>
                Nutzungsdaten (z. B. Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und -frequenz, verwendete Gerätetypen und Betriebssysteme, Interaktionen mit Inhalten und Funktionen); Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte Personen); Protokolldaten (z. B. Logfiles betreffend Logins oder den Abruf von Daten oder Zugriffszeiten.). Inhaltsdaten (z. B. textliche oder bildliche Nachrichten und Beiträge sowie die sie betreffenden Informationen, wie z. B. Angaben zur Autorenschaft oder Zeitpunkt der Erstellung).
            </p>
            <p>
                <strong>Betroffene Personen: </strong>
                Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten).
            </p>   
            <p>
                <strong>Zwecke der Verarbeitung: </strong>
                Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und technischen Geräten (Computer, Server etc.).); Sicherheitsmaßnahmen. Erbringung vertraglicher Leistungen und Erfüllung vertraglicher Pflichten.
            </p>   
            <p>
                <strong>Aufbewahrung und Löschung: </strong>
                Löschung entsprechend Angaben im Abschnitt "Allgemeine Informationen zur Datenspeicherung und Löschung".
            </p>   
            <p>
                <strong>Rechtsgrundlagen: </strong>
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </p>       
        
            <h2>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</h2>
                <ul>
                    <li>
                        <strong>Bereitstellung Onlineangebot auf gemietetem Speicherplatz: </strong>
                        Für die Bereitstellung unseres Onlineangebotes nutzen wir Speicherplatz, Rechenkapazität und Software, die wir von einem entsprechenden Serveranbieter (auch "Webhoster" genannt) mieten oder anderweitig beziehen; Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                        <strong>Rechtsgrundlagen: </strong>
                        Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                    </li>
                    <li>
                        <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>
                        Der Zugriff auf unser Onlineangebot wird in Form von sogenannten "Server-Logfiles" protokolliert. Zu den Serverlogfiles können die Adresse und der Name der abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite) und im Regelfall IP-Adressen und der anfragende Provider gehören. Die Serverlogfiles können zum einen zu Sicherheitszwecken eingesetzt werden, z. B. um eine Überlastung der Server zu vermeiden (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken), und zum anderen, um die Auslastung der Server und ihre Stabilität sicherzustellen; Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO). Löschung von Daten: Logfile-Informationen werden für die Dauer von maximal 30 Tagen gespeichert und danach gelöscht oder anonymisiert. Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der Löschung ausgenommen.
                    </li>
                    <li>
                        <strong>E-Mail-Versand und -Hosting: </strong>
                        Die von uns in Anspruch genommenen Webhosting-Leistungen umfassen ebenfalls den Versand, den Empfang sowie die Speicherung von E-Mails. Zu diesen Zwecken werden die Adressen der Empfänger sowie Absender als auch weitere Informationen betreffend den E-Mailversand (z. B. die beteiligten Provider) sowie die Inhalte der jeweiligen E-Mails verarbeitet. Die vorgenannten Daten können ferner zu Zwecken der Erkennung von SPAM verarbeitet werden. Wir bitten darum, zu beachten, dass E-Mails im Internet grundsätzlich nicht verschlüsselt versendet werden. Im Regelfall werden E-Mails zwar auf dem Transportweg verschlüsselt, aber (sofern kein sogenanntes Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht auf den Servern, von denen sie abgesendet und empfangen werden. Wir können daher für den Übertragungsweg der E-Mails zwischen dem Absender und dem Empfang auf unserem Server keine Verantwortung übernehmen; 
                        <strong>Rechtsgrundlagen: </strong>
                        Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                    </li>
                    <li>
                        <strong>1&1 IONOS: </strong>
                        Leistungen auf dem Gebiet der Bereitstellung von informationstechnischer Infrastruktur und verbundenen Dienstleistungen (z. B. Speicherplatz und/oder Rechenkapazitäten); 
                        <strong>Dienstanbieter: </strong>
                        1&1 IONOS SE, Elgendorfer Str. 57, 56410 Montabaur, Deutschland; 
                        <strong>Rechtsgrundlagen: </strong>
                        Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); 
                        <strong>Website:</strong>
                        <a href="https://www.ionos.de" class="link-infotext">https://www.ionos.de</a>;
                        <strong>Datenschutzerklärung: </strong>
                        <a href="https://www.ionos.de/terms-gtc/terms-privacy" class="link-infotext">https://www.ionos.de/terms-gtc/terms-privacy</a>.
                        <strong>Auftragsverarbeitungsvertrag: </strong>
                        <a href="https://www.ionos.de/hilfe/datenschutz/allgemeine-informationen-zur-datenschutz-grundverordnung-dsgvo/auftragsverarbeitung/" class="link-infotext">https://www.ionos.de/hilfe/datenschutz/allgemeine-informationen-zur-datenschutz-grundverordnung-dsgvo/auftragsverarbeitung/</a>.
                    </li>
                </ul>
        <h1 id="Cookies">Einsatz von Cookies</h1>
            <p>
                Unter dem Begriff „Cookies" werden Funktionen, die Informationen auf Endgeräten der Nutzer speichern und aus ihnen auslesen, verstanden. Cookies können ferner in Bezug auf unterschiedliche Anliegen Einsatz finden, etwa zu Zwecken der Funktionsfähigkeit, der Sicherheit und des Komforts von Onlineangeboten sowie der Erstellung von Analysen der Besucherströme. Wir verwenden Cookies gemäß den gesetzlichen Vorschriften. Dazu holen wir, wenn erforderlich, vorab die Zustimmung der Nutzer ein. Ist eine Zustimmung nicht notwendig, setzen wir auf unsere berechtigten Interessen. Dies gilt, wenn das Speichern und Auslesen von Informationen unerlässlich ist, um ausdrücklich angeforderte Inhalte und Funktionen bereitstellen zu können. Dazu zählen etwa die Speicherung von Einstellungen sowie die Sicherstellung der Funktionalität und Sicherheit unseres Onlineangebots. Die Einwilligung kann jederzeit widerrufen werden. Wir informieren klar über deren Umfang und welche Cookies genutzt werden.
            </p>
            <p>
                <strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>
                Ob wir personenbezogene Daten mithilfe von Cookies verarbeiten, hängt von einer Einwilligung ab. Liegt eine Einwilligung vor, dient sie als Rechtsgrundlage. Ohne Einwilligung stützen wir uns auf unsere berechtigten Interessen, die vorstehend in diesem Abschnitt und im Kontext der jeweiligen Dienste und Verfahren erläutert sind.
            </p>
            <p>
                <strong>Speicherdauer: </strong>
                Im Hinblick auf die Speicherdauer werden die folgenden Arten von Cookies unterschieden:
            </p>
            <ul>
                <li>
                    <strong>Temporäre Cookies (auch: Session- oder Sitzungscookies): </strong>
                    Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein Onlineangebot verlassen und sein Endgerät (z. B. Browser oder mobile Applikation) geschlossen hat.
                </li>
                <li>
                    <strong>Permanente Cookies: </strong>
                    Permanente Cookies bleiben auch nach dem Schließen des Endgeräts gespeichert. So können beispielsweise der Log-in-Status gespeichert und bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht. Ebenso können die mithilfe von Cookies erhobenen Nutzerdaten zur Reichweitenmessung Verwendung finden. Sofern wir Nutzern keine expliziten Angaben zur Art und Speicherdauer von Cookies mitteilen (z. B. im Rahmen der Einholung der Einwilligung), sollten sie davon ausgehen, dass diese permanent sind und die Speicherdauer bis zu zwei Jahre betragen kann.
                </li>
            </ul>
            <p>
                <strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-out): </strong>
                Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit widerrufen und zudem einen Widerspruch gegen die Verarbeitung entsprechend den gesetzlichen Vorgaben, auch mittels der Privatsphäre-Einstellungen ihres Browsers, erklären.
            </p>
            <ul>
                <li>
                    <strong>Verarbeitete Datenarten: </strong>
                    Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte Personen).
                </li>
                <li>
                    <strong>Betroffene Personen: </strong>
                    Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten).
                </li>
                <li>
                    <strong>Rechtsgrundlagen: </strong>
                    Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO). Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).
                </li>
            </ul>
            <h2>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</h2>
            <ul>
                <li>
                    <strong>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: </strong>
                    Wir setzen eine Einwilligungs-Management-Lösung ein, bei der die Einwilligung der Nutzer zur Verwendung von Cookies oder zu den im Rahmen der Einwilligungs-Management-Lösung genannten Verfahren und Anbietern eingeholt wird. Dieses Verfahren dient der Einholung, Protokollierung, Verwaltung und dem Widerruf von Einwilligungen, insbesondere bezogen auf den Einsatz von Cookies und vergleichbaren Technologien, die zur Speicherung, zum Auslesen und zur Verarbeitung von Informationen auf den Endgeräten der Nutzer eingesetzt werden. Im Rahmen dieses Verfahrens werden die Einwilligungen der Nutzer für die Nutzung von Cookies und die damit verbundenen Verarbeitungen von Informationen, einschließlich der im Einwilligungs-Management-Verfahren genannten spezifischen Verarbeitungen und Anbieter, eingeholt. Die Nutzer haben zudem die Möglichkeit, ihre Einwilligungen zu verwalten und zu widerrufen. Die Einwilligungserklärungen werden gespeichert, um eine erneute Abfrage zu vermeiden und den Nachweis der Einwilligung gemäß der gesetzlichen Anforderungen führen zu können. Die Speicherung erfolgt serverseitig und/oder in einem Cookie (sogenanntes Opt-In-Cookie) oder mittels vergleichbarer Technologien, um die Einwilligung einem spezifischen Nutzer oder dessen Gerät zuordnen zu können. Sofern keine spezifischen Angaben zu den Anbietern von Einwilligungs-Management-Diensten vorliegen, gelten folgende allgemeine Hinweise: Die Dauer der Speicherung der Einwilligung beträgt bis zu zwei Jahre. Dabei wird ein pseudonymer Nutzer-Identifikator erstellt, der zusammen mit dem Zeitpunkt der Einwilligung, den Angaben zum Umfang der Einwilligung (z. B. betreffende Kategorien von Cookies und/oder Diensteanbieter) sowie Informationen über den Browser, das System und das verwendete Endgerät gespeichert wird;
                    <strong>Rechtsgrundlagen: </strong>
                    Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).
                </li>
            </ul>
            <p>
                <strong></strong>
                
            </p>
                <li>
                    <strong></strong>
                    
                </li>
                <li>
                    <strong></strong>
                    
                </li>
                <li>
                    <strong></strong>
                    
                </li>



    </div>
</template>

<script>
export default {
    name: 'PrivacyStatement'
}
</script>
