import { createRouter, createWebHistory } from 'vue-router'

import store from '../store'
import OKRs from '../views/OKRs.vue'
import OKRs_Archive from '@/views/OKRs_Archive.vue'
// import Product from '../views/Product.vue'
import Search from '../views/Search.vue'
import Teams from '../views/Teams.vue'
import Company from '@/views/Company.vue'
import SignUp from '../views/SignUp.vue'
import LogIn from '../views/LogIn.vue'
import MyAccount from '../views/MyAccount.vue'
import Overview from '../views/Overview.vue'
import FrontPage from '@/views/FrontPage.vue'
import TestDataAnnotation from '@/views/TestDataAnnotation.vue'
import Changelog from '@/views/Changelog.vue'
import PrivacyStatement from '@/views/PrivacyStatement.vue'
import TermsAndConditions from '@/views/TermsAndConditions.vue'
import Imprint from '@/views/Imprint.vue'





const routes = [
  // {
  //   path: '/overview',
  //   name: 'Overview',
  //   component: Overview,
  //   meta: {
  //     requireLogin: true
  //   }
  // },
  {
    path: '/okrs',
    name: 'OKRs',
    component: OKRs,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/okrs-archive',
    name: 'OKRs_Archive',
    component: OKRs_Archive,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/about',
    name: 'AboutView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/sign-up-to-okr',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/log-in-to-okr',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: MyAccount,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: {
      requireLogin: true
    }
  },

  {
    path: '/teams',
    name: 'Teams',
    component: Teams,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/company',
    name: 'Company',
    component: Company,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/changelog',
    name: 'Changelog',
    component: Changelog,
    meta: {
      requireLogin: true
    }
  },
  // {
  //   path: '/front-page',
  //   name: 'Front-Page',
  //   component: FrontPage,
  //   meta: {
  //     requireLogin: false
  //   }
  // },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/privacy-statement',
    name: 'PrivacyStatement',
    component: PrivacyStatement,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint,
    meta: {
      requireLogin: false
    }
  },
  // {
  //   path: '/test-data-annotation',
  //   name: 'TestDataAnnotation',
  //   component: TestDataAnnotation,
  //   meta: {
  //     requireLogin: false
  //   }
  // },



]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin)  && !store.state.isAuthenticated) {
    next({ name: 'LogIn', query: { to: to.path} });
  } else {
    next()
  }
})

export default router
