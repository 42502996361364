<template>
    <div>
        <!-- <div id="wrapper">
            <nav class="navbar-top-left">
              <div class="navbar-top-menu">
                  <div class="navbar-top-item">
                      <router-link to="/front-page" class="button-navbar-top">vanta OKR</router-link>
                  </div>
                </div>
            </nav>
        </div> -->
        
        <div class="main-content">
            <h1 class="title horizontal-center">Registrieren</h1>

            <form @submit.prevent="submitForm">
                <!-- <div class="field">
                    <div class="text-left">Nutzername</div>
                    <div class="control">
                        <input type="text" class="input w-[250px]" v-model="username">
                    </div>
                </div> -->

                <div class="field">
                    <div class="text-left">E-Mail Adresse</div>
                    <div class="control">
                        <input type="text" class="input w-[250px]" v-model="email">
                    </div>
                </div>

                <div class="field">
                    <div class="text-left">Passwort</div>
                    <div class="control">
                        <input type="password" class="input w-[250px]" v-model="password">
                    </div>
                </div>

                <!-- <div class="field">
                    <div class="text-left">Passwort wiederholen</div>
                    <div class="control">
                        <input type="password" class="input w-[250px]" v-model="password2">
                    </div>
                </div> -->

                <div class="notification is-danger" v-if="errors.length">
                    <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                </div>

                <div class="field">
                    <div class="control">
                        <button class="button is-dark">Registrieren</button>
                    </div>
                </div>

                <div class="horizontal-center">
                    <hr>Zum Login <RouterLink class="m-[5px]" to="/log-in-to-okr">hier klicken</RouterLink>
                </div>

            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
    name: 'SignUp',
    data() {
        return {
            // username: '',
            licenceNumber: '',
            email: '',
            password: '',
            password2: '',
            errors: []
        }
    },
    methods: {
        submitForm() {
            console.log('submitForm()')

            // if (this.username === '') {
            //     this.errors.push('The username is missing')
            // }

            
            if (this.password === '') {
                this.errors.push('The password is too short')
            }

            
            // if (this.password !== this.password2) {
            //     this.errors.push('The passwords don\'t match')
            // }

            if (this.email === '') {
                this.errors.push('The email is missing')
            }

            if (!this.errors.length) {
                const formData = {
                    // username: this.username,
                    email: this.email,
                    // username: this.email,

                    // licenceNumber: this.licenceNumber,
                    password: this.password
                }
                console.log('formData:', formData)
                axios
                .post("/api/v1/users/", formData)
                .then(response => {
                    toast({
                        message: 'Account created, please log in!',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })

                    this.$router.push('/log-in-to-okr')
                })
                .catch(error => {
                    if (error.respnse) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }

                        console.log(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.log(JSON.stringify(error.message))
                        this.errors.push('Something went wrong. Please try again')

                        console.log(JSON.stringify(error))
                    }
                })

            }
        }
    }
}
</script>