<template>
    <div class="notification is-info mt-4 text-center" v-if="!isPlanningActive">
        Achtung: Sie machen gerade eine Eintragung <br>
        außerhalb der dafür vorgesehenen Planningzeit.
    </div>
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
        Keyresult ändern
    </strong>
    <table>
        <tbody>
            <tr>
                <td class="vertical-center">Name:</td>
                <td class="vertical-center">
                    <div>
                        <input type="text" class="input-field-big" v-model=editedKeyresult.name>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Owner:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <select class="input-field-big" v-model=editedKeyresult.owner>
                            <option v-for="user in team_users" :value="user.id">{{ user.username }} </option>
                        </select>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Start:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-medium" v-model.number=editedKeyresult.start @input="validateProgress">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Goal:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-medium" v-model.number=editedKeyresult.goal @input="validateProgress">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Current value:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-medium" v-model.number=editedKeyresult.value_current>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Unit:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <select class="input-field-small" v-model=editedKeyresult.unit @change="handleCustomUnit">
                            <option value="%" selected>%</option>
                            <option value="$">$</option>
                            <option value=" ">custom</option>
                        </select>
                        <div v-if="customSelected">
                            <input type="text" class="input-field-small" v-model=editedKeyresult.customInput>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Status:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <select class="input-field-big" v-model=editedKeyresult.status>
                            <!-- <option value="" disabled selected>Status</option> -->
                            <option value="Not started" selected>Not started</option>
                            <option value="Off track">Off track</option>
                            <option value="Progressing">Progressing</option>
                            <option value="On track">On track</option>
                        </select>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Weight multiplier</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-medium" v-model.number=editedKeyresult.weight_multiplier>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center"></td>
                <td class="vertical-center"></td>
            </tr>
            <tr>
                <td>
                    &nbsp;
                </td>
            </tr>
        </tbody>
    </table>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-dark" @click="submitForm">Save</button>
    </div>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-danger" @click="deleteKeyresult">Delete</button>
    </div>
    <div class="flex-center">
        <span class="help-icon" @mouseover="showHelpText = true" @mouseleave="showHelpText = false">
            ?
        </span>
        <span v-if="showHelpText" class="help-text">Your help text goes here</span>
    </div>
    <div class="notification is-danger mt-4" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Modal_ChangeKeyresult',
    emits: ['keyresultChanged'],
    props: {
        keyresult: Object,
        team_users: Array,
        isPlanningActive: Boolean,
    },
    data() {
        return {
            editedKeyresult: { ...this.keyresult },
            errors: [],
            team: null,
            customSelected: false
        }
    },
    mounted() {
        console.log("editedKeyresult:", this.editedKeyresult)
    },
    methods: {
        submitForm() {
            this.errors = []

            if (this.editedKeyresult.name === '') {
                this.errors.push('The name field is missing!')
            }
            if (this.editedKeyresult.owner === '') {
                this.errors.push('The owner field is missing!')
            }
            if (this.editedKeyresult.start === '') {
                this.errors.push('The start value is missing!')
            }
            if (this.editedKeyresult.goal === '') {
                this.errors.push('The goal value is missing!')
            }
            if (this.editedKeyresult.value_current === '' || isNaN(+this.editedKeyresult.value_current)) {
                this.editedKeyresult.value_current = this.editedKeyresult.start
            }
            if (this.editedKeyresult.status === '') {
                this.errors.push('The status field is missing!')
            }
            if (this.editedKeyresult.weight_multiplier === '') {
                this.editedKeyresult.weight_multiplier = 1
            }
            if (!this.errors.length) {
                // this.progress = parseInt(this.progress);
                if (this.editedKeyresult.unit === 'custom') {
                    this.editedKeyresult.unit = this.customInput
                };
                this.changeKeyResult();
                this.editedKeyresult.unit = '%';
                // this.user = localStorage.getItem('user')
            }
        },
        async changeKeyResult() {
            this.$store.commit('setIsLoading', true)
            await axios
                .put(`/api/v1/keyresults/${this.keyresult.id}/`, 
                {
                objective: this.editedKeyresult.objective.id,
                name: this.editedKeyresult.name,
                team: this.editedKeyresult.objective.team, 
                owner: this.editedKeyresult.owner,
                unit: this.editedKeyresult.unit,
                start: this.editedKeyresult.start,
                goal: this.editedKeyresult.goal,
                value_current: this.editedKeyresult.value_current,
                status: this.editedKeyresult.status,
                weight_multiplier: this.editedKeyresult.weight_multiplier,
                },
                )
                .then(response => {
                this.$emit('keyresultChanged');
                })
                .catch(error => {
                console.error('Error:', error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async deleteKeyresult() {
            this.$store.commit('setIsLoading', true)
            await axios
                .delete(`/api/v1/keyresults/${this.keyresult.id}/`)
                .then(response => {
                this.$emit('keyresultChanged');
                })
                .catch(error => {
                console.error('Error:', error)
                })
            this.$store.commit('setIsLoading', false)
        },
        handleCustomUnit() {
            this.validateProgress()
            if (this.unit === ' ') {
                this.customSelected = true;
            } else {
                this.customSelected = false;
            }
        },
        validateProgress() {
            // TODO: Don't delete, needs to be adjusted
            if (this.unit === '%') {
                if (this.start < 0) {
                    this.start = 0;
                } else if (this.goal > 100) {
                    this.goal = 100;
                }
            }
        }
    }
}
</script>

<style scoped>


</style>
