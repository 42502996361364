<template>
    <div class="overview-header">
        <strong>
            {{ objective.name }}
        </strong>
    </div>

    <div class="overview-background-wrapper">
        <div class="overview-background bg-[#4d8ee2]" v-for="keyresult in filteredKeyresults" v-bind:key="keyresult.id">
            <div class="actually-centered">
                <button @click="isModalOpen = true" class="button is-dark round-button-small ">x{{ keyresult.weight_multiplier }}</button>
                <Component_Overview_Keyresult
                        v-bind:keyresult="keyresult"
                        v-bind:permissions="permissions"
                />
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
import Component_Overview_Keyresult from '@/components/Component_Overview_Keyresult'

export default {
    name: 'Component_Overview_Objective',
    components:
    {
        Component_Overview_Keyresult,
    },
    props: {
        objective: Object,
        keyresults: Array,
        permissions: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            filteredKeyresults: [],
        }
    },
    mounted() {
        // this.getKeyresults();
        this.filterKeyresults();
    },
    methods: { 
        async filterKeyresults() {
            this.filteredKeyresults = this.keyresults.filter(keyresult => keyresult.objective === this.objective.id);
        },
        // async getKeyresults() {
        //     this.$store.commit('setIsLoading', true);
        //     try {
        //         const response = await axios.get(`/api/v1/keyresults/?objective_id=${this.objective.id}`);
        //         this.keyresults = response.data;

        //     } catch (error) {
        //         console.error('Error fetching objectives:', error);
        //     }
        //     this.$store.commit('setIsLoading', false);
        // },
    }
}
</script>