<template>    
    <td class="text-center" v-if="permissions.includes(`olp_change_objective_${objective.id}`)">
        <button  class="actionButton" @click="isChangeObjectiveModalOpen = true" >Edit</button>
        <Teleport to="#modal">
            <Transition name="modal">
                <div v-if="isChangeObjectiveModalOpen" class="modal-bg" @click.self="isChangeObjectiveModalOpen = false">
                    <div class="modal">
                        <Component_Modal_InfoButton :infoText="infotextChangeObjective" />
                        <button @click="isChangeObjectiveModalOpen = false" class="close-btn">x</button>
                        <Modal_ChangeObjective 
                        v-bind:objective="objective"
                        v-bind:keyresults="filteredKeyresults"
                        v-bind:activeWorkingPeriods="activeWorkingPeriods"
                        v-bind:isPlanningActive="isPlanningActive"
                        v-on:objectiveChanged="$emit('objectiveChanged')"
                        v-on:keyresultChanged="$emit('keyresultChanged')"
                        />
                        <!-- v-if="permissions.includes(`olp_change_objective_partly_${objective.id}`) || permissions.includes(`olp_change_objective_all_${objective.id}`)" -->
                    </div>
                </div>
            </Transition>
        </Teleport>
    </td>

    <td class="text-center" v-if="isPrior && permissions.includes('product.add_objective')">
        <button class="actionButton"  @click="isAddObjectivePlanningModalOpen = true" >Übernehmen</button>
        <Teleport to="#modal">
            <Transition name="modal">
                <div v-if="isAddObjectivePlanningModalOpen" class="modal-bg" @click.self="isAddObjectivePlanningModalOpen = false">
                    <div class="modal">
                        <button @click="isAddObjectivePlanningModalOpen = false" class="close-btn">x</button>
                        <Modal_AddObjective_Planning
                        v-bind:objective="objective"
                        v-bind:keyresults="filteredKeyresults"
                        v-bind:activeWorkingPeriods="activeWorkingPeriods"
                        v-on:objectiveAdded="$emit('objectiveAdded')"
                        v-on:objectiveDeleted="$emit('objectiveDeleted')"
                        v-on:keyresultChanged="$emit('keyresultChanged')"
                        />
                    </div>
                </div>
            </Transition>
        </Teleport>
    </td>
</template>

<script>
import Modal_ChangeObjective from './Modal_ChangeObjective.vue';
import Modal_AddObjective_Planning from './Modal_AddObjective_Planning.vue';
import Component_Modal_InfoButton from '@/components/Component_Modal_InfoButton'
import { Teleport, Transition } from 'vue';

export default {
    name: 'Component_OKR_Objective_ActionsNormal',
    emits: ['objectiveAdded','objectiveChanged', 'keyresultChanged'],
    components: {
        Modal_ChangeObjective,
        Modal_AddObjective_Planning,
        Component_Modal_InfoButton,
    },
    props: {
        objective: Object,
        keyresults: Array,
        filteredKeyresults: Array,
        activeWorkingPeriods: Array,
        permissions: Array,
        isPrior: Boolean,
        isPlanningActive: Boolean,
    },
    data() {
        return {
            infotextChangeObjective: 'Objectives werden rein Qualitativ definiert.',
            isChangeObjectiveModalOpen: false,
            isAddObjectivePlanningModalOpen: false,
        }
    },
}
</script>