<template>
    <div v-if="!isLoading" class="flex flex-wrap">
        <div header class="column is-12">
            <h2 class="is-size-3 has-text-centered pb-3">Übersicht</h2>
        </div>
        <div class="overview-background-wrapper" v-if="!this.isLoading">
            <div class="overview-background bg-[#b3d7ff]" v-for="vision in visions" v-bind:key="vision.id">
                <Component_Overview_Vision 
                    v-bind:vision="vision"
                    v-bind:missions="missions"
                    v-bind:moals="moals"
                    v-bind:objectives="objectives"
                    v-bind:keyresults="keyresults"
                    v-bind:permissions="permissions"
                    v-on:visionChanged="handleSomethingChanged"
                >
                </Component_Overview_Vision>
            </div>
        </div>
        <table class="table is-fullwidth no-header-border table-fixed"  v-if="!this.isLoading">
            <tr class="inline-container">
                <th class="overview-background overview-legend bg-[#b3d7ff]">
                    <div class="inline-content">
                        Vision
                        <button v-if="permissions.includes('product.add_vision')" @click="isAddVisionModalOpen = true" class="button is-dark round-button ml-[15px]">+</button>
                        <Teleport to="#modal">
                            <Transition name="modal">
                                <div v-if="isAddVisionModalOpen" class="modal-bg" @click.self="isAddVisionModalOpen = false">
                                    <div class="modal">
                                    <button @click="isAddVisionModalOpen = false" class="close-btn">x</button>
                                    <Modal_AddVision
                                    v-if="permissions.includes('product.add_vision')"
                                    v-on:visionAdded="handleSomethingChanged" 
                                    />                    
                                    </div>
                                </div>
                            </Transition>
                        </Teleport>
                    </div>
                </th>
                <th class="overview-background overview-legend #b3d7ff">
                    <div class="inline-content">
                        Mission
                        <button v-if="permissions.includes('product.add_mission')" @click="isAddMissionModalOpen = true" class="button is-dark round-button ml-[15px]">+</button>
                        <Teleport to="#modal">
                            <Transition name="modal">
                                <div v-if="isAddMissionModalOpen" class="modal-bg" @click.self="isAddMissionModalOpen = false">
                                    <div class="modal">
                                    <button @click="isAddMissionModalOpen = false" class="close-btn">x</button>
                                    <Modal_AddMission
                                    v-if="permissions.includes('product.add_mission')"
                                    v-on:missionAdded="handleSomethingChanged" 
                                    v-bind:visions="visions"
                                    />                    
                                    </div>
                                </div>
                            </Transition>
                        </Teleport>
                    </div>
                </th>
                <th class="overview-background overview-legend #b3d7ff">
                    <div class="inline-content">
                        Moal
                        <button v-if="permissions.includes('product.add_mission')" @click="isAddMoalModalOpen = true" class="button is-dark round-button ml-[15px]">+</button>
                        <Teleport to="#modal">
                            <Transition name="modal">
                                <div v-if="isAddMoalModalOpen" class="modal-bg" @click.self="isAddMoalModalOpen = false">
                                    <div class="modal">
                                    <button @click="isAddMoalModalOpen = false" class="close-btn">x</button>
                                    <Modal_AddMoal
                                    v-if="permissions.includes('product.add_moal')"
                                    v-on:moalAdded="handleSomethingChanged" 
                                    v-bind:missions="missions"
                                    />                    
                                    </div>
                                </div>
                            </Transition>
                        </Teleport>
                    </div>
                </th>
                <th class="overview-background overview-legend #b3d7ff">
                    Objective
                </th>
                <th class="overview-background overview-legend #b3d7ff">
                    Key Result
                </th>
            </tr>
        </table>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

import Component_Overview_Vision from '@/components/Component_Overview_Vision'
import Modal_AddVision from '@/components/Modal_AddVision'
import Modal_AddMission from '@/components/Modal_AddMission'
import Modal_AddMoal from '@/components/Modal_AddMoal'


export default {
    name: 'Overview',
    components:
    {
        Component_Overview_Vision,
        Modal_AddVision,
        Modal_AddMission,
        Modal_AddMoal,
    },
    data() {
        return {
            visions: [],
            missions: [],
            moals: [],
            objectives: [],
            keyresults: [],
            permissions: [],
            isAddVisionModalOpen: false,
            isAddMissionModalOpen: false,
            isAddMoalModalOpen: false,
            isLoading: true,
        }
    },
    mounted() {
        this.loadData();
        // this.getVisions();
        // this.getMissions();
        // this.getMOALs();
        // this.getObjectives();
        // this.getKeyResults();
        // this.fetchPermissions();
    },
    computed: {
        ...mapState({
            isLoading: state => state.isLoading
        }),
    },
    methods: { 
        async loadData() {
            this.$store.commit('setIsLoading', true);
            try {
                await Promise.all([
                    this.getVisions(), 
                    this.getMissions(), 
                    this.getMOALs(), 
                    this.getObjectives(), 
                    this.getKeyResults(), 
                    this.fetchPermissions()
                ])
                this.isLoading = false
                console.log('data:', "Visions", this.visions, "Missions", this.missions, "MOALs", this.moals, "Objectives", this.objectives, "Key Results", this.keyresults, "Permissions", this.permissions);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.$store.commit('setIsLoading', false);
            }  
        },
        async getVisions() {
            this.$store.commit('setIsLoading', true);
            try {
                const response = await axios.get('/api/v1/visions/');
                this.visions = response.data;
                console.log('visions:', this.visions);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
            this.$store.commit('setIsLoading', false);
        },
        async getMissions() {
            // this.$store.commit('setIsLoading', true);
            try {
                const response = await axios.get('/api/v1/missions/');
                this.missions = response.data;
                console.log('missions:', this.missions);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
            // this.$store.commit('setIsLoading', false);
        },
        async getMOALs() {
            // this.$store.commit('setIsLoading', true);
            try {
                const response = await axios.get('/api/v1/moals/');
                this.moals = response.data;
                console.log('moals:', this.moals);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
            // this.$store.commit('setIsLoading', false);
        },
        async getObjectives() {
            // this.$store.commit('setIsLoading', true);
            try {
                const response = await axios.get('/api/v1/objectives/');
                this.objectives = response.data;
                console.log('objectives:', this.objectives);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
            // this.$store.commit('setIsLoading', false);
        },
        async getKeyResults() {
            // this.$store.commit('setIsLoading', true);
            try {
                const response = await axios.get('/api/v1/keyresults/');
                this.keyresults = response.data;
                console.log('keyresults:', this.keyresults);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
            // this.$store.commit('setIsLoading', false);
        },
        async fetchPermissions() {
            try {
                const response = await axios.get('/api/v1/users/me/permissions'); 
                this.permissions = response.data.permissions;
                // console.log('permissions:', this.permissions);
            } catch (error) {
            }
        },
        async handleSomethingChanged() {
            await this.loadData();
            this.isAddVisionModalOpen = false;
            this.isAddMissionModalOpen = false;
            this.isAddMoalModalOpen = false;
            // this.isChangeVisionModalOpen = false;
            // this.isChangeMissionModalOpen = false;
            // this.isChangeMoalModalOpen = false;
            // this.$store.dispatch('fetchData');
        },
    }
}


</script>

<style>
.overview-legend {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px; /* Adjust padding as needed */

    margin-top: 1rem;
    width: auto;
    height: auto;
}
.inline-container {
    display: flex;
    align-items: center; /* Adjust vertical alignment as needed */
    justify-content: space-between; /* Adjust this as per your layout needs */
}
.inline-content{
    display: flex;
    align-items: center; /* This ensures vertical alignment if needed */
    /* margin-left: 8px;  */
}

</style>