<template>
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
        Übernehmen
    </strong>
    <table>
        <tbody>
            <tr>
                <td class="vertical-center">Name:</td>
                <td class="vertical-center">
                    <div>
                        <input type="text" class="input-field-big" v-model="editedObjective.name">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Team:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <select class="input-field-big" v-model="editedObjective.team"> 
                            <option v-for="team in teamsTeamLead" :value="team.id">{{ team.name }}</option>
                        </select>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Status:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <select class="input-field-big" v-model="editedObjective.status">
                            <option value="Not started">Not started</option>
                            <option value="Off track">Off track</option>
                            <option value="Progressing">Progressing</option>
                            <option value="On track">On track</option>
                        </select>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Moals:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <select class="input-field-big" multiple v-model="editedObjective.moals">
                            <option v-for="moal in moals_fetched" :value="moal.id">{{ moal.name }}</option>
                        </select>
                    </div>
                </td>
            </tr>
            <tr v-if="editedObjective.moals && editedObjective.moals.length > 0">
                <td>
                    &nbsp;
                </td>
            </tr>
            <tr v-if="editedObjective.moals && editedObjective.moals.length > 0">
                <td class="vertical-center">
                    <strong>
                        Gewichte MOALs:
                    </strong>
                </td>
            </tr>
            <tr v-for="(moalId, index) in editedObjective.moals" :key="index">
                <td class="vertical-center">{{ findMoalName(moalId) }}:</td>
                <td class="vertical-center">
                    <div>
                        <input 
                            type="number" 
                            class="input-field-big" 
                            v-model="moalWeights[moalId]" 
                        />
                    </div>
                </td>
            </tr>
            <tr v-if="keyresults.length > 0">
                <td>
                    &nbsp;
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Arbeitszeitraum:</td>
                <td class="vertical-center">
                    <div class="radio-group">
                        <div v-for="period in activeWorkingPeriods" :key="period.id" class="radio-option">
                            <input
                            type="radio"
                            :value="period"
                            :id="`period-${period.value}`"
                            v-model="selectedWorkingPeriod"
                            name="workingPeriod"
                            >
                            <label :for="`period-${period.value}`" class="spaced"> {{ period.name }}</label>
                        </div>
                    </div>
                </td> 
            </tr>
            <tr>
                <td>
                    &nbsp;
                </td>
            </tr>
            <tr>
                <td class="vertical-center">
                    <div class="checkbox">
                        <input
                            type="checkbox"
                            v-model="isKeyresultsSelected"
                        >
                        <label> Keyresults auch übernehmen</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    &nbsp;
                </td>
            </tr>
        </tbody>
    </table>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-dark" @click="submitForm">Übernehmen</button>
    </div>
    <div class="notification is-danger mt-4" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>
    <div class="flex-center">
        <span class="help-icon" @mouseover="showHelpText = true" @mouseleave="showHelpText = false">
            ?
        </span>
        <span v-if="showHelpText" class="help-text">Your help text goes here</span>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    name: 'Modal_AddObjective_Planning',
    emits: ['objectiveAdded', 'objectiveDeleted', 'keyResultAdded'], 
    props: {
        objective: Object,
        keyresults: Array, 
        activeWorkingPeriods: Array,
    },
    data() {
        return {
            editedObjective: { ...this.objective },
            newObjectiveId: '',
            editedKeyresults: [...this.keyresults],
            errors: [],
            moals_fetched: [],
            moalWeights: {},
            selectedWorkingPeriod: {},
            customSelected: false,
            showHelpText: false,
            isKeyresultsSelected: true,
        }
    },
    mounted() {
        this.fetchTeams();
        this.getMOALs();
        this.initializeMoalWeights();
        console.log('editedObjective:', this.editedObjective);
        console.log('editedKeyresults:', this.editedKeyresults);
        console.log('activeWorkingPeriods:', this.activeWorkingPeriods);
    },
    computed: {
        ...mapState([
            'teamsTeamLead',
        ])
    },
    methods: {
        async submitForm() {
            this.errors = []

            if (this.editedObjective.name === '') {
                this.errors.push('The name field is missing!')
            }
            
            if (this.editedObjective.team === '') {
                this.errors.push('The team field is missing!')
            }
            if (this.editedObjective.status === '') {
                this.errors.push('The status field is missing!')
            }
            if (!this.errors.length) {
                this.progress = parseInt(this.progress);
                if (this.unit === 'custom') {
                    this.unit = this.customInput
                };
                await this.addObjective();
                this.deleteObjective();
                if (this.editedKeyresults && this.isKeyresultsSelected) {
                    await this.addKeyresults();  // Ensure this is awaited
                }
                this.$emit('objectiveAdded'); // TODO might lead to bugs
                this.unit = '%';
            }
        },
        async addObjective() {
            const payload = {
                name: this.editedObjective.name,
                team: this.editedObjective.team, 
                owner: this.editedObjective.owner,
                status: this.editedObjective.status,
                moals: this.editedObjective.moals,
                multiplier_objective_moals: this.prepareMoalsForSubmission(),
                working_period: this.selectedWorkingPeriod.id,
            }
            this.$store.commit('setIsLoading', true);

            try {
                const response = await axios.post('/api/v1/objectives/', payload);
                console.log('Response:', response.data);
                this.newObjectiveId = response.data.id;
            } catch (error) {
                console.error('Error adding objective:', error);
            } finally {
                this.$store.commit('setIsLoading', false);
            }
        },
        async addKeyresults() {
            if (this.editedKeyresults.length > 1) {
                for (const keyresult of this.editedKeyresults) {
                    try {
                        await axios.post(`/api/v1/keyresults/`, {
                            objective: this.newObjectiveId,
                            name: keyresult.name,
                            team: keyresult.team,
                            owner: keyresult.owner,
                            unit: keyresult.unit,
                            start: keyresult.start,
                            goal: keyresult.goal,
                            value_current: keyresult.value_current,
                            status: keyresult.status,
                            weight_multiplier: keyresult.weight_multiplier,
                        });
                    } catch (error) {
                        console.error('Error:', error);
                    }
                }
            } else {
                try {
                    await axios.post(`/api/v1/keyresults/`, {
                        objective: this.newObjectiveId,
                        name: this.editedKeyresults[0].name,
                        team: this.editedKeyresults[0].team,
                        owner: this.editedKeyresults[0].owner,
                        unit: this.editedKeyresults[0].unit,
                        start: this.editedKeyresults[0].start,
                        goal: this.editedKeyresults[0].goal,
                        value_current: this.editedKeyresults[0].value_current,
                        status: this.editedKeyresults[0].status,
                        weight_multiplier: this.editedKeyresults[0].weight_multiplier,
                    });
                    this.$emit('keyresultAdded');
                } catch (error) {
                    console.error('Error:', error);
                }
            }
            this.$store.commit('setIsLoading', false);
        },
        deleteObjective() {
            this.$store.commit('setIsLoading', true)
            axios
                .delete(`/api/v1/objectives/${this.objective.id}/`)
                .then(response => {
                this.$emit('objectiveDeleted');
                })
                .catch(error => {
                    if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else if (error.request) {
                    console.log(error.request);
                    } else {
                    console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
 this.$store.commit('setIsLoading', false)
        },
        fetchTeams() {
            this.$store.dispatch('fetchTeamsTeamlead')
                .then(() => {
                });
        },
        async getMOALs() {
            this.$store.commit('setIsLoading', true);
            try {
                const response = await axios.get(`/api/v1/moals/`);
                this.moals_fetched = response.data;
                console.log('MOALs:', this.moals_fetched)

            } catch (error) {
                console.error('Error fetching moals:', error);
            }
            this.$store.commit('setIsLoading', false);
        },
        findMoalName(id) {
            const moal = this.moals_fetched.find(moal => moal.id === id);
            return moal ? moal.name : 'Unknown';
        },
        initializeMoalWeights() {
            this.editedObjective.moals.forEach(moalId => {
                const index = this.editedObjective.multiplier_objective_moals?.findIndex(moal => moal.moal === moalId);
                if (index !== -1) {
                    this.moalWeights[moalId] = this.editedObjective.multiplier_objective_moals[index].weight;
                } else {
                    this.moalWeights[moalId] = 0; // Default value if not found
                }
            });
        },
        prepareMoalsForSubmission() {
            const connections = [];
            for (const moalId in this.moalWeights) {
                connections.push({
                    moal: moalId,
                    weight: this.moalWeights[moalId]
                });
            }
            return connections;
        },
        handleCustomUnit() {
            this.validateProgress()
            if (this.unit === ' ') {
                this.customSelected = true;
            } else {
                this.customSelected = false;
            }
        },
    },
    watch: {
        'editedObjective.moals': {
            handler(newVal, oldVal) {
                // Initialize weight for newly added moals
                newVal.forEach(id => {
                    if (!this.moalWeights[id]) {
                        this.moalWeights[id] = 1; // Default weight of 1
                    }
                });
                // Remove weight for deselected moals
                for (const id in this.moalWeights) {
                    if (!newVal.includes(Number(id))) {
                        delete this.moalWeights[id];
                    }
                }
            },
            deep: true
        }
    }
}
</script>