<template>
  <div>
    <!-- Your template code here -->
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
      Username ändern
    </strong>
    <table>
      <tbody>
        <tr>
          <td class="vertical-center">Neuer Nutzername:</td>
          <td class="vertical-center">
            <div>
              <input type="text" class="input-field-big" v-model="newUsername" />
            </div>
          </td>
        </tr>
        <tr>
          <td class="vertical-center">Passwort:</td>
          <td class="vertical-center">
            <div>
              <input type="password" class="input-field-big" v-model="currentPassword" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
      <button type="submit" class="button is-dark" @click="updateUser">Save</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Modal_MyAccount_ChangeUserName',
  emits: ['usernameChanged'],
  data() {
    return {
      newUsername: '',
      currentPassword: '',
      authToken: localStorage.getItem('token'), // Retrieve the token from local storage or another source
    };
  },
  methods: {
    async updateUser() {
      try {
        const response = await axios.post('/api/v1/users/set_username/', {
          new_username: this.newUsername,
          current_password: this.currentPassword,
          // new_email: 'test@hotmail.de',
          // re_new_email: "test@hotmail.de",
          // re_new_username: this.reNewUsername,

          // current_password: "sustevia",
        });
        console.log(response.data.message);
        this.$emit('usernameChanged');

      } catch (error) {
        if (error.response) {
          console.error('Error changing username:', error.response.data);
          // alert(`Error changing username: ${error.response.data.detail}`);
        } else {
          console.error('Error changing username:', error.message);
          // alert('An error occurred while changing the username.');
        }
      }
      // if (!this.authToken) {
      //   alert('Authentication token is missing.');
      //   return;
      // }
      // try {
      //   const response = await axios.post('/api/v1/users/set_email/', {
      //     new_email: "test@hotmail.de",
      //     re_new_email: "test@hotmail.de",
      //     current_password: "sustevia",
      //   }, {
      //     headers: {
      //       Authorization: `Token ${this.authToken}`,
      //     },
      //   });
      //   console.log(response.data.message);
      // } catch (error) {
      //   if (error.response) {
      //     console.error('Error changing email:', error.response.data);
      //     // alert(`Error changing email: ${error.response.data.detail}`);
      //   } else {
      //     console.error('Error changing email:', error.message);
      //     // alert('An error occurred while changing the email.');
      //   }
      // }
    },
  },
};
</script>