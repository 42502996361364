<template>
  <!-- <div id="wrapper">
      <nav class="navbar-top-left">
          <div class="navbar-top-menu">
              <div class="navbar-top-item">
                  <router-link to="/front-page" class="button-navbar-top">vanta OKR</router-link>
              </div>
          </div>
      </nav>
  </div>   -->
  
  <div class="mainContent">
    
    <div class="flex justify-center items-center">
      
      <pre class="text-left w-[40%]">
        <h1 class="title horizontal-center text-left">Impressum</h1>
Informationspflichten nach § 5 Telemediengesetz (TMG), § 36 Verbraucherstreitbeilegungsgesetz (VSBG) und §§ 2,3 Dienstleistungs- und Informationspflichten Verordnung (DL-Info VO).


Unternehmen in Gründung
Hildastraße 9d
76534 Baden-Baden
Vertreten durch: Sven Michael Willems, Moritz Reiss und Luka Johnsen 
Kontakt:
  Telefon: 015561339183
  E-Mail: Post@sustevia.de

Redaktionell verantwortlich: Sven Michael Willems, Moritz Reiss und Luka Johnsen
EU-Streitschlichtung: Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr/ .
Unsere E-Mail-Adresse finden Sie oben im Impressum.


Dieses Impressum gilt auch für die Darstellung unseres Unternehmens auf:
  YouTube
  Facebook
  LinkedIn
  XING
  Twitter
  Indeed DE
  Glassdoor DE
  Kununu DE
  Instagram
  Medium DE

Inhaltlich verantwortlich Sind Sven Michael Willems, Moritz Reiss und Luka Johnsen. Aufgrund der Informationspflichten gemäß § 36 VSBG teilen wir mit, dass wir nicht bereit und nicht verpflichtet sind, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle im Sinne des § 2 VSGB teilzunehmen.
Aufgrund der Verpflichtung aus der DL-Info VO weisen wir darauf hin, dass die Plattform der EU zur außergerichtlichen Online-Streitbeilegung unter folgendem Link abrufbar ist:
https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=DE
      </pre>
    </div>
  </div>

</template>

<style lang="scss" scoped>
/* Add your custom styles here */

.navbar-container-front {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%; /* Adjust based on your preference */
//   height: 100px;
  display: flex;
  justify-content: center; /* Center the navbar horizontally */
  align-items: center; /* Center the navbar vertically */
  background-color: white;
}





.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Full height minus navbar height */
  text-align: center;
//   transform: translateX(-125px); /* Shift 125px to the left */
}

.main-content h1 {
  font-size: 5em; /* Adjust as needed */
  margin-bottom: 20px;
  font-weight: bold;
}

.main-content button {
  font-size: 1.2em; /* Adjust as needed */
  padding: 10px 20px;
}

</style>