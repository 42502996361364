<template>
    <div class="flex flex-col items-center">
        <strong>
            {{ keyresult.name }}
        </strong>
    </div>

    <!-- <div class="has-background-info-light" v-for="objective in objectives" v-bind:key="objective.id">
        <Keyresult_Overview_Component
                v-bind:keyresult="keyresult"
                v-bind:permissions="permissions"
        </Keyresult_Overview_Component>
    </div> -->
</template>


<script>
import axios from 'axios'

export default {
    name: 'Component_Overview_Keyresult',
    // components:
    // {
    //     // Objective_Overview_Component,
    // },
    props: {
        keyresult: Object,
        permissions: {
            type: Array,
            required: true
        }
    },
    // data() {
    //     return {
    //         objectives: [],
    //     }
    // },
    // mounted() {
    //     this.getObjectives();
    // },
    // methods: { 
    //     async getObjectives() {
    //         this.$store.commit('setIsLoading', true);
    //         try {
    //             const response = await axios.get(`/api/v1/keyresults/?moal_id=${this.moal.id}`);
    //             this.objectives = response.data;

    //         } catch (error) {
    //             console.error('Error fetching keyresults:', error);
    //         }
    //         this.$store.commit('setIsLoading', false);
    //     },
    // }
}
</script>