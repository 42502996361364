<template>
    <div class="sidebar-icon group">
        <component :is="icon" class="h-6 w-6 text-gray-500" />
        <span class="sidebar-tooltip group-hover:scale-100">
            {{ text }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'Component_Sidebar_Icon',
    props: {
        icon:  {
            type: Object,
            required: true,
        },
        text:{
            type: String,
            required: false,
            default: 'Tooltip'
        }
    },
    data() {
        return {
            showMobileMenu: false
        }
    }
}
</script>